<template>
  <v-container fluid>
    <v-row>
      <v-col col="12" md="12" lg="12" outlined>
        <v-card class="pa-4 rounded-xl">
          <v-card-title class="justify-left d-flex flex-row">
            <p>Cupones</p>
            <v-spacer></v-spacer>
            <v-btn class="ma-3 rounded-lg text-capitalize" dense color="primary" :to="{ name: 'cupones-add' }">
              Agregar Cupones
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12" lg="12">
                  <table-coupon></table-coupon>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

        </v-card>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import TableCoupon from "@/components/tables/TableCoupon.vue";
export default {
  name: "coupon-index",
  components: { TableCoupon },
  data: () => ({}),
};
</script>

<style>
</style>