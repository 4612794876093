<template>
    <v-row align="center" class="list">
        <v-col cols="12" sm="12">
            <v-data-table :mobile-breakpoint="0" class="iconTable" :headers="headers" :items="arrayCoupons.data"
                disable-pagination :hide-default-footer="true" :loading="fetchingData"
                :loading-text="'Cargando... Espere por favor'" :no-data-text="'datos no disponibles'" item-key="_id"
                hide-default-header>
                <template #header="{ props: { headers } }">
                    <thead class="v-data-table-header" style="background-color: #299F7E;">
                        <tr>
                            <template v-for="header in headers">
                                <th :key="header.value" :class="header.align">
                                    <v-row>
                                        <v-col cols="12">
                                            <h2 style="color: white">
                                                {{ header.text }}
                                            </h2>
                                        </v-col>
                                    </v-row>
                                </th>
                            </template>
                        </tr>
                    </thead>
                </template>
                <template #[`item.codigo`]="{ item }">
                    <td :style="
                        arrayCoupons.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        {{ item.coupon_code }}
                    </td>
                </template>
                <template #[`item.description`]="{ item }">
                    <td :style="
                        arrayCoupons.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        {{ item.description }}
                    </td>
                </template>
                <template #[`item.discount`]="{ item }">
                    <td :style="
                        arrayCoupons.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        S/. {{ item.coupon_amount }}
                    </td>
                </template>
                <template #[`item.start_date_text`]="{ item }">
                    <td :style="
                        arrayCoupons.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        {{ item.start_date_text }}
                    </td>
                </template>
                <template #[`item.start_date_text`]="{ item }">
                    <td :style="
                        arrayCoupons.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        {{ item.start_date_text }}
                    </td>
                </template>

                <template #[`item.end_date_text`]="{ item }">
                    <td :style="
                        arrayCoupons.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        {{ item.end_date_text }}
                    </td>
                </template>

                <template #[`item.status`]="{ item }">
                    <td :style="
                        arrayCoupons.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        <!-- {{ item.status }} -->
                        <v-switch color="primary" v-model="item.status"  disabled></v-switch>
                    </td>
                </template>
                <template #[`item.actions`]="{ item }">
                    <td :style="
                        arrayCoupons.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        <v-btn icon color="primary" :to="'/cupones/edit/' + item.id">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </td>
                </template>
            </v-data-table>
        </v-col>

        <v-col cols="12" sm="12">
            <v-row>
                <v-col cols="12" sm="12" class="d-flex justify-end">
                    <v-pagination v-model="page" :length="arrayCoupons.last_page" total-visible="7"
                        next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="handlePageChange" circle>
                    </v-pagination>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "table-coupons",
    watch: {
        text: function (value) {
            this.getFetch();
        },
    },
    data: () => ({
        headers: [
            { text: "Código", value: "codigo", width: 150, sortable: false },
            { text: "Descripción", value: "description", sortable: false },
            { text: "Descuento", value: "discount", sortable: false },
            { text: "Inicia en", value: "start_date_text", sortable: false },
            { text: "Termina en", value: "end_date_text", sortable: false },
            { text: "Estado", value: "status", sortable: false },
            { text: "Acciones", value: "actions", sortable: false },
        ],
        page: 1,
        totalPages: 0,
        pageSizes: [10, 20, 30],
        pageSize: 10,
        //   loading: false
    }),
    computed: {
        ...mapState({
            arrayCoupons: (state) => state.coupon.arrayCoupons,
            fetchingData: (state) => state.fetchingData,
        }),
        text() {
            return this.$route.query.text;
        },
    },
    methods: {
        ...mapActions(["fetchCoupons"]),
        getRequestParams(page, pageSize) {
            let params = {};

            if (this.text) {
                params["search"] = this.text;
            }
            if (page) {
                params["page"] = page - 1;
            }
            if (pageSize) {
                params["per_page"] = pageSize;
            }
            return params;
        },
        handlePageChange(value) {
            this.page = value;
            this.getFetch();
            //this.retrieveTutorials();
        },

        handlePageSizeChange(size) {
            this.pageSize = size;
            this.page = 1;
            this.getFetch();
            //this.retrieveTutorials();
        },
        getFetch() {
            const params = this.getRequestParams(this.page, this.pageSize);
            this.fetchCoupons({ params: params });
        },
        // getCurrentYear() {
        //   return new Date().getFullYear();
        // },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style>
</style>